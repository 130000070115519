.blog_intro{
	width: 100%;
	height: 600px;
	
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-size: cover;
	background-position: center;
	text-align: center;
	
	h2,h4{
		position: relative;
		top: 40px;
		color: white;
	}

	.photo_filter{
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: black;
		opacity: 0.25;
		z-index: 1;
	}
}

.blog_content_detail{
	width: 100%;
	padding: 5%;
	word-spacing:.5rem;
	line-height: 1.5rem;
}