.post_area{
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	
	.blog_post{
		// width: 100%;
		height: 250px;
		display: flex;
		position: relative;
		font-size: 1.1rem; 
		cursor: pointer;

		@media screen and (max-width: 1200px) {  
			height: 375px;
		}

		@media screen and (max-width: 750px) { 
			height: 500px !important;
			font-size: 1.1em;
			flex-direction: column;
			
			.blog_image{
				max-width: 100% !important;
				max-height: 250px !important;
				background-position: center;
			}
		}

		.blog_image{
			min-width: 25%;
			max-width: 200px;
			height: 100%;
			background-size: cover;
		}
		
		.blog_meta{		
			position: absolute;
			bottom: 15px;	
			
			span{
				padding-top: 15px;
			}

			.blog_author{
				position: relative;
				top: 5px;
			}
		}
		
		.blog_content{
			padding: 0px 25px;
		}

		.blog_read_more{
			float: right;
			position: absolute;
			bottom: 15px;
			right: 15px;
		}

	}
}

.card{
	background-color: white;
    margin: 10px 10px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 0 100px rgba(175, 182, 194, 0.2);
}
