.blog_intro{
	width: 100%;
	min-height: 500px;
	
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-size: cover;
	
	h2,h4{
		position: relative;
		top: 40px;
		color: black !important;
	}

	.photo_filter{
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: black;
		opacity: 0.25;
		z-index: 1;
	}
}

.blog-intro--img{
	background-position: center center;
	background-image: url('/assets/img/blog_banner2.png');
}
