.certification_content{
	padding: 45px 10%;
	width: 100%;
	font-size: 1.3rem;
	font-weight: 100;

	li{
		padding-left: 3rem;
		margin-bottom: .8rem;
	}

	.cta{
		margin-top: 45px;
		text-align: center;
		font-size: 1.5rem;
		font-weight: 400;
	}
}

.certification__list{
	&--second{
		li{
			list-style-type: disc;
		}
	}

	&--third{
		li{
			list-style-type: decimal;
		}
	}
}