.education_content{
	padding: 45px 10%;
	width: 100%;
	font-size: 1.3rem;
	font-weight: 100;
	line-height: 2rem;

	li{
		padding-left: 3rem;
		margin-bottom: .8rem;
		list-style-type: disc;
		list-style-position: inside;
	}

	.cta{
		margin-top: 45px;
		text-align: center;
		font-size: 1.5rem;
		font-weight: 400;
	}
}

.education__list{
	margin-top: 40px;
}

.evp--img{
	background-position: center center;
	background-image: url('/assets/img/evp_banner.png');	
}